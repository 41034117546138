<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Users
			.widget-body

				.row
					.col-sm-8.form-inline
						.input-group.input-group-inline.input-group-rounded
							input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
						| &nbsp;
						.form-group.mr-1
							select.form-control(v-model="filters.status")
								option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
						.form-group
							button.btn.btn-primary(type="button", @click="loadData()") Go
					.col-sm-4.text-right
						router-link.btn.btn-success(:to="{name:'user-add'}")
							i.la.la-plus
							| Add

				p.clearfix &nbsp;

				.ov-table-wrapper
					.tabs.table-tabs
						ul.nav.nav-tabs
							li.nav-item(v-for="t in tabs")
								a.nav-link(:class="{active:selectedTab===t.v}", @click="selectTab(t.v)") {{ t.t }}
					table.table.table-bordered
						thead
							tr
								th ID
								th Active
								th Type
								th Name
								th Email
								th Joined
								th Actions
						tbody
							tr(v-if="!records.length")
								td(colspan="7") No matching records were found
							tr(v-for="r in records", v-bind:key="r.id")
								td {{ r.id }}
								td
									span(v-if="r.status===0")
										i.la.la-times
									span(v-if="r.status===1")
										i.la.la-check
								td
									span(v-if="r.type==='admin'") Administrator
									span(v-if="r.type==='am'") Account Manager
									span(v-if="r.type==='publisher'") Publisher
									span(v-if="r.type==='advertiser'") Advertiser
								td {{ r.name }}
								td {{ r.email }}
								td {{ r.created_at }}
								td.actions
									router-link.btn.btn-sm.btn-secondary.mr-1(:to="{name:'user-edit', params: {id:r.id}}")
										i.la.la-pencil
									// a.btn.btn-sm.btn-secondary.mr-1(href="")
										i.la.la-trash

					paginate(:paginator="paginate", @update-page-size="loadData")


</template>
<script>
export default {
  name: 'UserList',
  data() {
    return {
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 10,
        onPageChange: () => {
          this.loadData();
        },
      },
      filters: {
        keyword: '',
        status: null,
      },
      statusOptions: [
        { v: null, t: 'All' },
        { v: 1, t: 'Active' },
        { v: 0, t: 'Inactive' },
      ],
      tabs: [
        { v: 'admin', t: 'Admins' },
        { v: 'am', t: 'Account Managers' },
        { v: 'publisher', t: 'Publishers' },
        { v: 'advertiser', t: 'Advertisers' },
        // { v: 'general', t: 'General'},
      ],
      selectedTab: 'admin',
      records: [],
    };
  },
  methods: {
    async loadData() {
      this.busy = true;
      let params = {
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
        type: this.selectedTab,
      };
      if (this.filters.status !== null) {
        params.status = this.filters.status;
      }
      try {
        let resp = await this.$ovReq.get('user/getList', { params });
        this.records = resp.records;
        this.paginate.total = resp.total;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (e) {
        this.$ovNotify.error(e);
      }
      this.busy = false;
    },

    async selectTab(tab) {
      if (this.busy) {
        return;
      }
      this.paginate.page = 1;
      this.selectedTab = tab;
      await this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
};
</script>
